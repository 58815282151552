import React, { useEffect, useState } from 'react';
import {
  Col,
  Container,
  Form,
  Label,
  Row,
  Input,
  FormGroup,
  Button,
} from 'reactstrap';
import Lottie from 'lottie-react';
import ImageComponent from '../../components/ui/image';
import BlockText from '../../components/ui/block-text';
import StarIcon from '../../assets/images/star.svg';
import HandLottie from '../../assets/lotties/bff-calculator-hand.json';
import CloudLottie from '../../assets/lotties/bff-calculator-cloud.json';
import Multiselect from 'multiselect-react-dropdown';
import { Boxes, BoxesRadio } from './boxes';
import ServicesInfo from './servicesInfo';
import PriceTable from './priceTable';
import TrmApi from 'trm-api';
import numeral from 'numeral';

const talent = {
  options: [
    { name: 'Música', id: 'musica' },
    { name: 'Creador de contenido', id: 'creadorContenido' },
    { name: 'Deportista', id: 'deportista' },
    { name: 'TV y más', id: 'tvMas' },
    { name: 'Modelo', id: 'modelo' },
  ],
};

const followers = {
  options: [
    { name: '30K-100K', id: 'old' },
    { name: '100K-500M', id: 'bronze' },
    { name: '500k-1M', id: 'silver' },
    { name: '1M-2M', id: 'gold' },
    { name: '2M-5M', id: 'diamond' },
    { name: '5M-10M', id: 'platino' },
    { name: '10M-20M', id: 'palladium' },
    { name: '+20M', id: 'top' },
  ],
};

const Calculator = ({
  _rawTitle,
  _rawSecondTitle,
  _rawDisclamerTitle,
  _rawDisclamerParagraph,
  backgroundImage,
  backgroundPattern,
  disclamerIcon,
  interactionsBoxes,
}) => {
  const [profession, setProfession] = useState(null);
  const [professionId, setProfessionId] = useState(null);
  const [followersId, setfollowersId] = useState(null);
  const [services, setService] = useState([]);
  const [price, setPrice] = useState({});
  const [counter, setCounter] = useState({});
  const [currency, setCurrency] = useState('COP');
  const [finalPrice, setFinalPrice] = useState(0);
  const [trm, setTrm] = useState(0);

  const onSelectTalent = (_, selectedItem) => {
    setProfession(selectedItem.name);
    setProfessionId(selectedItem.id);
  };

  const onSelectFollowers = (_, selectedItem) => {
    setfollowersId(selectedItem.id);
  };

  const handelChangeServices = (childData) => {
    setService(childData);
  };

  const inputPrice = (service) => (e) => {
    const userPrice = e.target.value.slice(0, 8);
    if (parseInt(userPrice, 10) >= 0) {
      setPrice({ ...price, [service]: parseInt(userPrice, 10) });
    } else {
      setPrice({ ...price, [service]: '' });
    }
  };

  const increase = (service) => () => {
    if (Number.isNaN(counter[service]) || counter[service] === undefined) {
      setCounter({ ...counter, [service]: 1 });
    } else if (counter[service] >= 0 && counter[service] < 99) {
      setCounter({ ...counter, [service]: counter[service] + 1 });
    }
  };

  const decrease = (service) => () => {
    if (counter[service] > 0) {
      setCounter({ ...counter, [service]: counter[service] - 1 });
    }
  };

  const handleChangeCounter = (service) => (e) => {
    const userValue = e.target.value.slice(0, 2);
    if (parseInt(userValue, 10) >= 0) {
      setCounter({ ...counter, [service]: parseInt(userValue, 10) });
    } else {
      setCounter(0);
    }
  };

  const selectedCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const clearForm = () => {
    setCounter({});
    setPrice({});
  };

  const handleFocus = (e) => e.target.select();

  useEffect(() => {
    const trmApi = new TrmApi();
    trmApi.latest().then((data) => setTrm(data.valor));
  }, []);

  useEffect(() => {
    const fixed = currency === 'COP' ? 0 : 2;
    const priceKeys = Object.keys(price);
    const totalPrice = priceKeys.reduce(
      (a, b) => a + (counter[b] * price[b] * 0.75 || 0),
      0,
    );
    setFinalPrice(Number(totalPrice).toFixed(fixed));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, price]);

  useEffect(() => {
    const priceKeys = Object.keys(price);
    if (priceKeys.length > 0) {
      if (currency === 'USD') {
        const converted = priceKeys.reduce(
          (a, b) => ({
            ...a,
            [b]: Number(price[b] / trm).toFixed(2),
          }),
          {},
        );
        setPrice(converted);
      } else if (currency === 'COP') {
        const converted = priceKeys.reduce(
          (a, b) => ({
            ...a,
            [b]: Number(price[b] * trm).toFixed(2),
          }),
          {},
        );
        setPrice(converted);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency, trm]);

  return (
    <section className="calculator">
      <section className="calculator__main-title">
        <div className="calculator--pink-background"></div>
        <Container>
          <Row className="calculator__main-row">
            <Col className="calculator__main-title" lg={6} xs={10}>
              <img src={StarIcon} alt="" className="calculator__icon-star" />
              <BlockText data={_rawTitle} />
            </Col>
            <Col
              className="calculator__background-image d-none d-md-block"
              lg={5}
            >
              <ImageComponent className="img-fluid" data={backgroundImage} />
            </Col>
          </Row>
          <Row className="calculator__interactions-collapse-container d-md-none">
            {interactionsBoxes.map((box, index) => (
              <ServicesInfo
                icon={box.icon}
                title={box.title}
                description={box.description}
                index={index}
              />
            ))}
          </Row>
        </Container>
      </section>
      <section className="lottie-banner p-10">
        <Container className="lottie-banner__container">
          <Row className="calculator__interactions-boxes-row d-none d-md-flex">
            {interactionsBoxes.map((box) => (
              <Boxes
                icon={box.icon}
                title={box.title}
                description={box.description}
                iconClass={box.iconClass}
              />
            ))}
          </Row>
          <Row className="calculator__second-title-container">
            <Col className="calculator__second-title" lg={7}>
              <img
                src={StarIcon}
                alt=""
                className="calculator__icon-star--second"
              />
              <BlockText data={_rawSecondTitle} />
            </Col>
            <Col
              lg={4}
              className="calculator__lottie-container d-none d-md-flex"
            >
              <Lottie
                className="calculator__lottie--cloud"
                animationData={CloudLottie}
              />
              <Lottie
                className="calculator__lottie--hand"
                animationData={HandLottie}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="form-banner p-10">
        <div className="d-none d-md-flex">
          <ImageComponent
            className="form-banner__background-pattern"
            data={backgroundPattern}
          />
        </div>
        <Container className="form-banner__container">
          <Col>
            <Form className="calculator__form" id="calculator-form">
              <Row>
                <Col xs={12} lg={6}>
                  <Label
                    className="calculator__form__select-label"
                    for="talentType"
                  >
                    ¿Qué tipo de talento eres?{' '}
                  </Label>
                  <Multiselect
                    placeholder="Selecciona tu talento"
                    options={talent.options}
                    selectedValues={talent.options.find(
                      (option) => option.value === professionId,
                    )}
                    onSelect={onSelectTalent}
                    displayValue="name"
                    className="calculator__multiple-select"
                    singleSelect={true}
                    hidePlaceholder={true}
                  />
                </Col>
                <Col xs={12} lg={6}>
                  <Label className="calculator__form__select-label">
                    Selecciona el número de seguidores
                  </Label>
                  <Multiselect
                    placeholder="Número de seguidores"
                    options={followers.options}
                    selectedValues={followers.options.find(
                      (option) => option.value === followersId,
                    )}
                    onSelect={onSelectFollowers}
                    displayValue="name"
                    className="calculator__multiple-select"
                    singleSelect={true}
                    hidePlaceholder={true}
                  />
                </Col>
              </Row>
              <Row className="calculator__boxes-container" xs={12}>
                <Label>¿Qué servicio te interesa?</Label>
                {interactionsBoxes.map((box) => (
                  <BoxesRadio
                    icon={box.icon}
                    title={box.title}
                    backgroundColor={box.backgroundColor}
                    secondaryIcon={box.secondaryIcon}
                    secondaryBackgroundColor={box.secondaryBackgroundColor}
                    setServices={handelChangeServices}
                    services={services}
                  />
                ))}
              </Row>
              <Row className="calculator__currency-row">
                <FormGroup className="calculator__currency-container">
                  <Label>Elige el tipo de moneda</Label>
                  <Col className="calculator__currency-container--types">
                    <Label className="calculator__currency-label" check>
                      COP
                      <Input
                        name="currency"
                        type="radio"
                        className="calculator__currency-type"
                        value="COP"
                        checked={currency === 'COP'}
                        onChange={selectedCurrency}
                      />
                    </Label>{' '}
                    <Label className="calculator__currency-label" check>
                      USD
                      <Input
                        name="currency"
                        type="radio"
                        className="calculator__currency-type"
                        value="USD"
                        checked={currency === 'USD'}
                        onChange={selectedCurrency}
                      />
                    </Label>{' '}
                  </Col>
                </FormGroup>
                {professionId && followersId && services && currency && (
                  <>
                    <Row className="calculator__disclamer-container" lg={8}>
                      <ImageComponent
                        className="calculator__disclamer-icon"
                        data={disclamerIcon}
                      />
                      <Label className="calculator__disclamer-title">
                        Tarifas sugeridas {profession}
                      </Label>
                    </Row>
                    <Row className="calculator__table__row" xs={12}>
                      <PriceTable
                        job={professionId}
                        level={followersId}
                        services={services}
                        currency={currency}
                      />
                    </Row>
                    <Row>
                      <BlockText
                        data={_rawDisclamerParagraph}
                        className="calculator__disclamer-paragraph"
                      />
                    </Row>
                  </>
                )}
              </Row>
              <Row className="calculator__price-input--background py-3 py-md-0">
                <Row className="mt-4 d-none d-md-flex">
                  <Col>
                    <Label className="calculator__price-input__label">
                      Define el precio de tus servicios
                    </Label>
                  </Col>
                  <Col className="text-center">
                    <Label className="calculator__price-input__label">
                      ¿Cuántos puedes responder al mes?
                    </Label>
                  </Col>
                </Row>
                <Row className="mt-4 d-flex d-md-none">
                  <Col>
                    <Label className="calculator__price-input__label">
                      Define el precio de tus servicios y cuántos puedes
                      responder al mes
                    </Label>
                  </Col>
                </Row>
                {services.map((service) => (
                  <Row className="calculator__price-input my-3 align-items-center">
                    <Col
                      xs={8}
                      md={6}
                      className="d-flex align-items-center px-md-0"
                    >
                      <div
                        style={{
                          backgroundColor: interactionsBoxes.find(
                            (box) => box.title === service,
                          ).secondaryBackgroundColor,
                        }}
                        className="justify-content-center calculator__box-icon-form-row me-2"
                      >
                        <ImageComponent
                          className="calculator__box-icon-form small"
                          data={
                            interactionsBoxes.find(
                              (box) => box.title === service,
                            ).secondaryIcon
                          }
                        />
                      </div>
                      <Input
                        placeholder={`${service}: $5 ${currency} - $200 ${currency}`}
                        className="calculator__price-input--size d-inline flex-grow-1"
                        type="tel"
                        value={price?.[service]}
                        onChange={inputPrice(service)}
                        onFocus={handleFocus}
                      />
                    </Col>
                    <Col xs={4} md={6} className="d-flex">
                      <div className="calculator__picker-container m-auto">
                        <Button
                          className="calculator__picker-button"
                          onClick={decrease(service)}
                          color="#ffe4cc"
                          outline
                        >
                          {' '}
                          -{' '}
                        </Button>
                        <Input
                          className="calculator__picker-display"
                          value={counter?.[service] || 0}
                          type="text"
                          pattern="[0-9]*"
                          onChange={handleChangeCounter(service)}
                        />
                        <Button
                          className="calculator__picker-button"
                          onClick={increase(service)}
                          color="#ffe4cc"
                          outline
                        >
                          {' '}
                          +{' '}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ))}
              </Row>

              <Row className="calculator__picker-row" xl={8}>
                <Col
                  className="calculator__calculated-amount py-0 py-md-3 px-4"
                  xs={12}
                >
                  <Label className="calculator__amount-text">
                    Puedes ganar apróximadamente
                  </Label>
                  <span className="calculator__amount d-none d-md-flex">
                    {numeral(finalPrice).format('$0,0.00')}
                    <span className="calculator__amount__currency">
                      {currency}/MES
                    </span>
                  </span>
                  <span className="calculator__amount text-center d-md-none d-lg-none d-xl-none">
                    {numeral(finalPrice).format('$0,0.00')}{' '}
                    <span className="calculator__amount__currency">
                      {currency}/MES
                    </span>
                  </span>
                </Col>
              </Row>
              <div className="calculator__button-container">
                <Button
                  className="calculator__erase-button"
                  onClick={clearForm}
                  size="lg"
                  color="#a515db"
                >
                  BORRAR DATOS
                </Button>
              </div>
            </Form>
          </Col>
        </Container>
      </section>
    </section>
  );
};

export default Calculator;
