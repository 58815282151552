import React, { useState } from 'react';
import { Col, Row, Input, Label } from 'reactstrap';
import ImageComponent from '../../components/ui/image';

const Boxes = ({ icon, title, description, iconClass }) => {
  return (
    <>
      <Col className="calculator__box-container">
        <Row>
          <ImageComponent className="calculator__box-icon" data={icon} />
        </Row>
        <Row className="calculator__box-title">{title}</Row>
        <div className="hidden">
          <i className={iconClass} />
          {description}
        </div>
      </Col>
    </>
  );
};

const BoxesRadio = ({
  icon,
  title,
  backgroundColor,
  secondaryIcon,
  secondaryBackgroundColor,
  services,
  setServices,
}) => {
  const [hover, setHover] = useState(false);
  const sectionStyle = {
    background: hover ? backgroundColor : '#fefdfa',
  };

  const sectionStyleSecondary = {
    background: hover ? '#fefdfa' : secondaryBackgroundColor,
  };

  const sectionStyleTitle = {
    color: hover ? '#fefdfa' : '#191919',
  };

  const selectedService = (e) => {
    const newServices = new Set(services);
    const value = e.target.value;
    if (newServices.has(value)) {
      newServices.delete(value);
    } else {
      newServices.add(value);
    }
    setServices([...newServices]);
  };

  return (
    <Col
      style={{ backgroundColor: sectionStyle.background }}
      className="calculator__box-container-form"
      align="center"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      xs={3}
    >
      <Label>
        <Row
          style={{ backgroundColor: sectionStyleSecondary.background }}
          className="justify-content-center calculator__box-icon-form-row"
        >
          <ImageComponent
            className="calculator__box-icon-form"
            data={secondaryIcon}
          />
        </Row>
        <Row
          className="calculator__box-title-form"
          style={{ color: sectionStyleTitle.color }}
        >
          {title}
        </Row>
        <Row className="justify-content-center">
          <Input
            type="radio"
            name={title}
            value={title}
            onClick={selectedService}
            checked={services.find((service) => service === title)}
          />
        </Row>
      </Label>
    </Col>
  );
};

export { Boxes, BoxesRadio };
