import React, { useState, useRef, useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import ReactPlayer from 'react-player';
import { useMediaQueries } from '@react-hook/media-query';
import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';
import useIntersectionObserver from '../../utils/hooks/useIntersectionObserver';
import classNames from 'classnames';
import IphoneEx from '../../assets/images/im_iphone@3x.png';
import StarIcon from '../../assets/images/purple-star.svg';

const BannerVideo = ({
  _key,
  _rawMain,
  _rawFirstParagraph,
  _rawSecondParagraph,
  icon,
  _rawIconTitle,
  _rawIconParagraph,
  bannerVideo,
  linkIcon,
}) => {
  const animationRef = useRef(null);
  const videoContainerRef = useRef(null);
  const isVisible = useIntersectionObserver(animationRef, {
    rootMargin: '-25% 0% 10% 0%',
    threshold: '0',
  });
  const isVideoVisible = useIntersectionObserver(videoContainerRef, {
    rootMargin: '0% 0% 0% 0%',
    threshold: '0',
  });
  const [preload, setPreload] = useState('none');
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const {
    matches: { isDesk },
  } = useMediaQueries({ isDesk: '(min-width: 992px)' });

  const mouseEnter = () => {
    setPlaying(true);
  };

  const mouseLeave = () => {
    setPlaying(false);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    setPreload('auto');
  }, []);

  useEffect(() => {
    if (!isVideoVisible) {
      mouseLeave();
    }
  }, [isVideoVisible]);

  return (
    <section className="banner-video h-100">
      <div className="banner-video--background"></div>
      <Container className="align-items-center h-100">
        <Row className="banner-video__main-container">
          <Col className="banner-video__image" xs={10} lg={4}>
            {/* Mobile */}
            {!isDesk && (
              <div className="video-container" ref={videoContainerRef}>
                <button
                  className="banner-video__mute-icon"
                  onClick={toggleMuted}
                >
                  <i
                    className={classNames(
                      { 'icon-ic_mute': muted },
                      { 'icon-ic_mute_active': !muted },
                    )}
                  />
                </button>
                <div onClick={togglePlay}>
                  <ReactPlayer
                    key={`${_key}-${preload}`}
                    volume
                    width="98%"
                    height="98%"
                    playsinline
                    muted={muted}
                    loop
                    playing={playing}
                    url={bannerVideo.link}
                    config={{
                      file: {
                        attributes: {
                          preload,
                        },
                      },
                    }}
                    className="banner-video__video-player"
                  />
                </div>
                <div className="video-container__talent-info">
                  <Row>
                    <Col xs={3}>
                      <ImageComponent
                        data={bannerVideo.profilePicture}
                        className="video-container__icon"
                      />
                    </Col>
                    <Col className="video-container__text-col" xs={9}>
                      <ImageComponent data={bannerVideo.countryFlag} />
                      <BlockText
                        data={bannerVideo._rawProfession}
                        className="video-container__talent-profesion"
                      />
                      <BlockText
                        data={bannerVideo._rawTalentName}
                        className="video-container__talent-name"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            {/* Large display */}
            {isDesk && (
              <div
                className="video-container"
                onMouseOver={mouseEnter}
                onMouseLeave={mouseLeave}
                role="button"
                tabIndex={0}
              >
                <button
                  className="banner-video__mute-icon "
                  onClick={toggleMuted}
                >
                  <i
                    className={classNames(
                      { 'icon-ic_mute': muted },
                      { 'icon-ic_mute_active': !muted },
                    )}
                  />
                </button>
                <img
                  src={IphoneEx}
                  alt=""
                  className="banner-video__phone img-fluid"
                />
                <div onClick={togglePlay}>
                  <ReactPlayer
                    volume
                    width="99%"
                    height="99%"
                    muted={muted}
                    playsinline
                    playing={playing}
                    url={bannerVideo.link}
                    className="banner-video__video-player"
                  />
                </div>
                <div className="video-container__talent-info">
                  <Row>
                    <Col lg={3}>
                      <ImageComponent
                        data={bannerVideo.profilePicture}
                        className="video-container__icon"
                      />
                    </Col>
                    <Col className="video-container__text-col" lg={9}>
                      <ImageComponent data={bannerVideo.countryFlag} />
                      <BlockText
                        data={bannerVideo._rawProfession}
                        className="video-container__talent-profesion"
                      />
                      <BlockText
                        data={bannerVideo._rawTalentName}
                        className="video-container__talent-name"
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </Col>
          <Col className="banner-video align-items-center" xs={12} lg={8}>
            <Row>
              <BlockText data={_rawMain} className="banner-video__title" />
            </Row>
            <Row>
              <Col className="banner-video__paragraph-icon" xs={12} lg={6}>
                <img src={StarIcon} alt="" className="d-none d-lg-block" />
                <BlockText
                  data={_rawFirstParagraph}
                  className="banner-video__paragraph"
                />
              </Col>
              <Col className="banner-video__paragraph-icon" xs={0} lg={6}>
                <img src={StarIcon} alt="" className=" d-none d-lg-block" />
                <BlockText
                  data={_rawSecondParagraph}
                  className="banner-video__paragraph  d-none d-lg-block"
                />
              </Col>
            </Row>
            <Row className="d-none d-lg-flex">
              <div className="banner-video__icon d-inline">
                <a
                  href={linkIcon}
                  target="_blank"
                  rel="noreferrer"
                  className="d-block"
                >
                  <ImageComponent data={icon} className="w-100" />
                </a>
              </div>
              <Col>
                <BlockText
                  data={_rawIconTitle}
                  className="banner-video__icon__title"
                />
                <BlockText
                  data={_rawIconParagraph}
                  className="banner-video__icon__paragraph"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <div
        ref={animationRef}
        className={classNames('row mx-0 background-text', {
          animate: isVisible,
        })}
      >
        <Col xs={12} className="px-0 background-text--left">
          <span>IMPUL</span>
        </Col>
        <Col xs={12} className="px-0 background-text--right text-end">
          <span>-SAMOS</span>
        </Col>
      </div>
    </section>
  );
};

export default BannerVideo;
