import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Input,
  Label,
  Row,
  Col,
  Button,
  FormGroup,
} from 'reactstrap';
import { Link, navigate } from 'gatsby';
import PhoneInput from 'react-phone-input-2';

import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';

const FormSection = ({
  _rawTitle,
  _rawDescription,
  image,
  backgroundImage,
}) => {
  const [phone, setPhone] = useState();
  const [disabled, setDisabled] = useState(true);
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const formInstance = useRef();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData(formInstance.current);
      let object = {};
      formData.forEach((value, key) => {
        object = { ...object, [key]: value };
      });
      Object.assign(object, { followers: parseInt(object.followers) });
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/talent-requests`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(object),
        },
      );
      if (response.status === 201) {
        navigate('/envio-exitoso');
        invalidMessage();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const invalidMessage = (e) => {
    const userInput = e?.target?.validity;
    const emailInput = e?.target?.value;

    if (userInput?.typeMismatch || !emailInput) {
      e?.target?.setCustomValidity(
        'Revisa el e-mail que ingresaste e inténtalo de nuevo.',
      );
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailInput)) {
      e?.target?.setCustomValidity(
        'Revisa el e-mail que ingresaste e inténtalo de nuevo.',
      );
    } else {
      e?.target?.setCustomValidity('');
    }
  };

  const invalidMessageUrl = (e) => {
    const value = e?.target?.value;
    e?.target?.setCustomValidity('');
    if (e?.target?.validity?.patternMismatch || !value) {
      e?.target?.setCustomValidity('La url que ingresaste no es valida.');
    }
  };

  const handleOnChange = (value) => {
    if (value.length === 12) {
      setPhone(value);
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  useEffect(() => {
    const getCountries = async () => {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/location/countries`,
      );
      const countries = await response.json();
      setCountries(countries);
    };
    const getCategories = async () => {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/v1/categories`,
      );
      const categories = await response.json();
      setCategories(categories);
    };
    getCountries();
    getCategories();
  }, []);
  return (
    <section className="Form">
      <ImageComponent data={backgroundImage} className="Form__background" />
      <Container className="Form__container">
        <Row>
          <Col md={6} className="Form__col-image">
            <ImageComponent data={image} className="Form__image" />
          </Col>
          <Col md={6} className="Form__col-form">
            <BlockText data={_rawTitle} className="Form__title" />
            <BlockText data={_rawDescription} className="Form__subtitle" />
            <form
              ref={formInstance}
              className="Form__form"
              onSubmit={handleSubmit}
            >
              <Label className="Form__label">
                <span>Tu nombre completo</span>
                <Input
                  className="Form__input"
                  name="name"
                  placeholder="Nombre y apellidos"
                  onKeyPress={(event) => {
                    if (!/[a-z ]/i.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required
                />
              </Label>
              <Label className="Form__label">
                <span>Tu número de celular</span>
                <PhoneInput
                  className="Form__input--phone"
                  placeholder="¿A dónde podemos llamarte?"
                  value={phone}
                  onChange={handleOnChange}
                  country="co"
                  inputProps={{
                    name: 'phoneNumber',
                    required: true,
                    autoFocus: false,
                  }}
                  isValid={(value) => {
                    if (value.length > 2 && value.length < 12) {
                      return 'Número de teléfono inválido';
                    } else {
                      return true;
                    }
                  }}
                />
              </Label>
              <Label className="Form__label">
                <span>Tu email</span>
                <Input
                  className="Form__input"
                  placeholder="jhon@gmail.com"
                  name="email"
                  type="email"
                  required
                  onChange={invalidMessage}
                />
                <div className="Form__icon-container">
                  <i className="icon-ic_mail Form__icon" />
                </div>
              </Label>
              <Label className="Form__label">
                <span>¿A qué te dedicas?</span>
                <Input
                  className="Form__input"
                  type="select"
                  name="profession"
                  required
                >
                  <option value="">Selecciona a qué te dedicas</option>
                  {categories.map((service) => (
                    <option value={service.name}>{service.name}</option>
                  ))}
                </Input>
              </Label>
              <Label className="Form__label">
                <span>¿De qué país eres?</span>
                <Input
                  className="Form__input"
                  type="select"
                  name="country"
                  required
                >
                  {countries.map((country) => (
                    <option
                      value={country.name}
                      selected={country.prefix === 'Co'}
                    >
                      {country.name}
                    </option>
                  ))}
                </Input>
              </Label>
              <Label className="Form__label">
                <span>¿Cuál es tu red social favorita?</span>
                <Input
                  className="Form__input"
                  type="select"
                  name="favoriteSocialNetwork"
                  required
                >
                  <option vlaue="">Selecciona la red social</option>
                  <option vlaue="Instagram">Instagram</option>
                  <option vlaue="Facebook">Facebook</option>
                  <option vlaue="Tiktok">Tiktok</option>
                </Input>
              </Label>
              <Label className="Form__label">
                <span>¿Cuántos seguidores tienes?</span>
                <Input
                  className="Form__input"
                  type="number"
                  min={0}
                  name="followers"
                  required
                />
              </Label>
              <Label className="Form__label">
                <span>Link a tu perfil</span>
                <Input
                  className="Form__input"
                  type="text"
                  name="profileUrl"
                  onChange={invalidMessageUrl}
                  pattern="(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)"
                  required
                />
              </Label>
              <FormGroup check inline className="Form__checkbox">
                <Input type="checkbox" required name="policies" />
                <Label check>
                  {`He leído y acepto las `}
                  <Link to="/politica-privacidad">
                    Políticas de privacidad.
                  </Link>
                </Label>
              </FormGroup>
              <Button
                type="submit"
                className="Form__button--primary"
                color="#A515DB"
                disabled={disabled}
              >
                ENVIAR
              </Button>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FormSection;
