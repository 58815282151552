import React from 'react';
import BannerHome from '../../sections/banner-home';
import BannerSpecial from '../../sections/banner-special';
import BannerVideo from '../../sections/banner-video';
import BffLine from '../../sections/bff-line';
import Faqs from '../../sections/faqs';
import FormSection from '../../sections/Form';
import IconsBanner from '../../sections/icons-banner';
import PrivacyPolicy from '../../sections/privacy-policy';
import SideBar from '../../sections/side-bar';
import StepsCarousel from '../../sections/steps-carousel';
import SubscribeToNewsletter from '../../sections/subscribe-to-newsletter';
import SuccessfullSend from '../../sections/successfull-send';
import VideoCarousel from '../../sections/video-carousel';
import Calculator from '../../sections/calculator';
import ToTopButton from '../../sections/to-top-button';

const ComponentRegistry = {
  'banner-home': ({ ...rest }) => <BannerHome {...rest} />,
  'banner-special': ({ ...rest }) => <BannerSpecial {...rest} />,
  'banner-video': ({ ...rest }) => <BannerVideo {...rest} />,
  'bff-line': ({ ...rest }) => <BffLine {...rest} />,
  faqs: ({ ...rest }) => <Faqs {...rest} />,
  'form-section': ({ ...rest }) => <FormSection {...rest} />,
  'icons-banner': ({ ...rest }) => <IconsBanner {...rest} />,
  'privacy-policy': ({ ...rest }) => <PrivacyPolicy {...rest} />,
  'side-bar': ({ ...rest }) => <SideBar {...rest} />,
  'steps-carousel': ({ ...rest }) => <StepsCarousel {...rest} />,
  'subscribe-to-newsletter': ({ ...rest }) => (
    <SubscribeToNewsletter {...rest} />
  ),
  'succesfull-send': ({ ...rest }) => <SuccessfullSend {...rest} />,
  'video-carousel': ({ ...rest }) => <VideoCarousel {...rest} />,
  calculator: ({ ...rest }) => <Calculator {...rest} />,
  'to-top-button': ({ ...rest }) => <ToTopButton {...rest} />,
};

export default ComponentRegistry;
