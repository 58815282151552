import React from 'react';
import { Col, Row } from 'reactstrap';
import ReactPlayer from 'react-player/lazy';

import ImageComponent from '../../components/ui/image';
import BlockText from '../../components/ui/block-text';
import StarIcon from '../../assets/images/star.svg';
import IphoneEx from '../../assets/images/im_iphone@3x.png';

const Carousel = ({
  backgroundSecondaryColor,
  stepIcon,
  titleColor,
  video,
  _rawDescription,
  _rawMain,
  _rawStepTitle,
  _rawTitleDescription,
}) => {
  return (
    <Row className="steps-carousel-v2__step-container">
      <Col lg={4}>
        <img src={StarIcon} alt="" className="steps-carousel-v2--icon-star" />
        <Row style={{ color: titleColor }}>
          <span className="steps-carousel-v2__title">LA APP DONDE</span>
          <BlockText
            data={_rawMain}
            className="steps-carousel-v2__title--color"
          />
        </Row>
        <BlockText
          data={_rawTitleDescription}
          className="steps-carousel-v2__description d-none d-md-block"
        />
      </Col>
      <Col className="video-column" lg={4}>
        <div className="background-video">
          <div
            className="background-video--left"
            style={{ backgroundColor: backgroundSecondaryColor }}
          />
          <div
            className="background-video--right"
            style={{ backgroundColor: backgroundSecondaryColor }}
          />
        </div>
        <div className="video-container-carousel">
          <img
            src={IphoneEx}
            alt=""
            className="banner-video-carousel__phone img-fluid"
          />
          <ReactPlayer
            volume
            width="99%"
            height="99%"
            playing={true}
            loop={true}
            muted
            playsinline
            url={video[0].link}
            className="banner-video-carousel__video-player"
          />
        </div>
      </Col>
      <Col className="steps-carousel-v2__step-info-container" lg={4}>
        <ImageComponent
          data={stepIcon}
          className="steps-carousel-v2__step-icon"
        />
        <BlockText
          data={_rawStepTitle}
          className="steps-carousel-v2__step-title"
        />
        <BlockText
          data={_rawDescription}
          className="steps-carousel-v2__step-description"
        />
      </Col>
    </Row>
  );
};

export default Carousel;
