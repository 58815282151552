import React from 'react';
import { Container } from 'reactstrap';
import Slider from 'react-slick';
import Carousel from './carousel';
import { CarouselNextArrow, CarouselPrevArrow } from './carouselArrows';
import ImageComponent from '../../components/ui/image';

const StepsCarousel = ({ steps }) => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          centerPadding: '40px',
          centerMode: false,
          infinite: false,
        },
      },
    ],
  };

  return (
    <section className="steps-carousel-v2__section">
      <Slider {...settings}>
        {steps.map((single) => {
          return (
            <section className="steps-carousel-v2">
              <div
                className="steps-carousel-v2--background"
                style={{ backgroundColor: single?.backgroundPrimaryColor }}
              >
                <ImageComponent
                  className="steps-carousel-v2--background-image"
                  data={single?.backgroundImage}
                />
              </div>
              <Container className="steps-carousel-v2__main-container">
                <Carousel
                  backgroundSecondaryColor={single.backgroundSecondaryColor}
                  stepIcon={single.stepIcon}
                  titleColor={single.titleColor}
                  video={single.video}
                  _rawDescription={single._rawDescription}
                  _rawMain={single._rawMain}
                  _rawStepTitle={single._rawStepTitle}
                  _rawTitleDescription={single._rawTitleDescription}
                />
              </Container>
            </section>
          );
        })}
      </Slider>
    </section>
  );
};

export default StepsCarousel;
