import { Link } from 'gatsby';
import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';

const SuccessfullSend = ({
  _rawDescriptionSuccess,
  _rawTitleSuccess,
  backgroundImage,
  image,
  icon,
}) => {
  return (
    <section className="SuccessfullSend">
      <ImageComponent
        data={backgroundImage}
        className="SuccessfullSend__background"
      />
      <Container className="SuccessfullSend__container">
        <Row className="SuccessfullSend__row">
          <Col md={6} className="SuccessfullSend__col-image">
            <ImageComponent data={image} className="SuccessfullSend__image" />
          </Col>
          <Col md={6} className="SuccessfullSend__col-form">
            <div className="SuccessfullSend__row--title">
              <figure className="SuccessfullSend__figure--check">
                <ImageComponent
                  data={icon}
                  className="SuccessfullSend__image"
                />
              </figure>
              <BlockText
                data={_rawTitleSuccess}
                className="SuccessfullSend__title"
              />
            </div>
            <BlockText
              data={_rawDescriptionSuccess}
              className="SuccessfullSend__subtitle"
            />
            <Link to="/">
              <Button
                className="SuccessfullSend__button--primary"
                color="#A515DB"
              >
                VOLVER
              </Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SuccessfullSend;
