import React, { useRef, useState } from 'react';
import { Container, Col, Row } from 'reactstrap';
import Slider from 'react-slick';
import classNames from 'classnames';

import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';
import StarIcon from '../../assets/images/star.svg';
import VideoComponent from '../../components/ui/video';
import {
  CarouselNextArrow,
  CarouselPrevArrow,
} from '../../components/ui/arrows-carousel';
import SpecialAnimation from '../../assets/lotties/bff-special.json';
import Lottie from 'lottie-react';
import useIntersectionObserver from '../../utils/hooks/useIntersectionObserver';

const BannerSpecial = ({ _rawMainSpecial, specialBoxes, image, video }) => {
  const animationRef = useRef(null);
  const isVisible = useIntersectionObserver(animationRef);
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    afterChange: (currentSlide) => {
      setCurrentIndex(currentSlide);
    },
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '40px',
          centerMode: true,
          infinite: false,
        },
      },
    ],
  };
  return (
    <section className="banner-special">
      <Container className="banner-special__container">
        <Row>
          <Col xs={12} md={8} className="banner-special__title">
            <img
              src={StarIcon}
              alt=""
              className="mb-3 banner-special__icon-star"
            />
            <BlockText data={_rawMainSpecial} />
          </Col>
          <Col xs={4} className="banner-special__image  d-none d-md-block">
            <Lottie animationData={SpecialAnimation} />
          </Col>
        </Row>
        <Row className="mt-3 mt-md-5 pb-5">
          {specialBoxes.map((box) => (
            <Col xs={12} md={4} className="banner-special__boxes-container">
              <figure className="image-container">
                <ImageComponent
                  data={box.icon}
                  className="banner-special__boxes-container__icon"
                />
              </figure>
              <h3 className="banner-special__boxes-container__title">
                {box.title}
              </h3>
              <p className="banner-special__boxes-container__description">
                {box.description}
              </p>
            </Col>
          ))}
        </Row>
        <Row className="mt-5  d-none d-md-block">
          <Slider {...settings} className="banner-special__video">
            {video.map((single, index) => {
              return (
                <div key={single}>
                  <h3 className="banner-special__video--player">
                    <div className="banner-special__video__container">
                      <VideoComponent
                        url={single.link}
                        _rawProfession={single._rawProfession}
                        _rawTalentName={single._rawTalentName}
                        profilePicture={single.profilePicture}
                        countryFlag={single.countryFlag}
                        index={index}
                        currentIndex={currentIndex}
                      />
                    </div>
                  </h3>
                </div>
              );
            })}
          </Slider>
        </Row>
      </Container>
      <Slider {...settings} className="d-md-none  banner-special__video">
        {video.map((single) => {
          return (
            <div key={single}>
              <h3 className="banner-special__video--player">
                <div className="banner-special__video__container">
                  <VideoComponent
                    url={single.link}
                    _rawProfession={single._rawProfession}
                    _rawTalentName={single._rawTalentName}
                    profilePicture={single.profilePicture}
                    countryFlag={single.countryFlag}
                  />
                </div>
              </h3>
            </div>
          );
        })}
      </Slider>
      <div
        ref={animationRef}
        className={classNames('row background-text-special mx-0', {
          animate: isVisible,
        })}
      >
        <Col xs={12} className="px-0 background-text-special--right text-end">
          <span>BEST</span>
        </Col>
        <Col xs={12} className="px-0 background-text-special--left">
          <span>FRIEND</span>
        </Col>
        <Col xs={12} className="px-0 background-text-special--right  text-end">
          <span>FAMOSO</span>
        </Col>
      </div>
    </section>
  );
};

export default BannerSpecial;
