import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  rootMargin: '-25% 0% -40% 0%',
  threshold: '0',
};

const useIntersectionObserver = (ref, options = DEFAULT_OPTIONS) => {
  const [isVisible, setVisible] = useState(false);

  const observerCallback = (entries) => {
    for (const entry of entries) {
      entry.isIntersecting && setVisible(true);
      !entry.isIntersecting && setVisible(false);
    }
  };
  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, options);
    const element = ref?.current;

    if (element) {
      observer.observe(element);
    }

    return () => observer.disconnect();
  }, [ref, options]);

  return isVisible;
};

export default useIntersectionObserver;
