import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ComponentRegistry from '../components/ui/registry';

const HomeTemplate = ({ data }) => {
  const {
    sanityHome: { tabs: { seo, sections: { sections = {} } = {} } = {} } = {},
  } = data;

  const mapSections = ({ type, ...rest }) => {
    if (Object.keys(ComponentRegistry).includes(type)) {
      return ComponentRegistry[type](rest);
    }
    return null;
  };

  return (
    <Layout>
      <Seo data={seo} />
      {sections.map(({ _type, ...rest }) =>
        mapSections({ type: _type, ...rest }),
      )}
    </Layout>
  );
};

export default HomeTemplate;
export const HomeTemplateQuery = graphql`
  query HomeTemplateQuery($id: String) {
    sanityHome(id: { eq: $id }) {
      tabs {
        seo {
          ...seoFragment
        }
        sections {
          sections {
            ... on SanityBannerHome {
              ...BannerHomeFragment
            }
            ... on SanityBannerSpecial {
              ...BannerSpecialFragment
            }
            ... on SanityBannerVideo {
              ...BannerVideofragment
            }
            ... on SanityBffLine {
              ...BffLineFragment
            }
            ... on SanityFaqs {
              ...FaqsFragament
            }
            ... on SanityFormSection {
              ...FormFragment
            }
            ... on SanityIconsBanner {
              ...IconsBannerFragment
            }
            ... on SanityPrivacyPolicy {
              ...PrivacyPolicyFragment
            }
            ... on SanitySideBar {
              ...SideBarFragment
            }
            ... on SanityStepsCarousel {
              ...StepsCarrouselFragment
            }
            ... on SanitySubscribeToNewsletter {
              ...SuscribeToNewsletterFragment
            }
            ... on SanitySuccesfullSend {
              ...SuccessfullSendFragment
            }
            ... on SanityVideoCarousel {
              ...VideoCarouselFragment
            }
            ... on SanityCalculator {
              ...CalculatorFragment
            }
            ... on SanityToTopButton {
              ...ToTopFragment
            }
          }
        }
      }
    }
  }
`;
