import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const BffLine = ({ title }) => {
  return (
    <section className="bff-line py-2">
      <Container>
        <Row>
          <Col>
            <span className="bff-line__title">
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
            </span>
            <span className="bff-line__title title-2">
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
              {title}{' '}
              <i className="icon-ic_famousstar_active bff-line__icon mx-2" />
            </span>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BffLine;
