import React from "react"
import ImageComponent from "../../components/ui/image"

const SideBar = ({ _rawTitle, image }) => {
  return (
    <section className="side-bar d-none d-lg-block">
      <ImageComponent data={image} className="side-bar" />
    </section>
  )
}

export default SideBar
