import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';
import StarIcon from '../../assets/images/star.svg';
import SpecialAnimation from '../../assets/lotties/bff-icons-banner.json';
import Lottie from 'lottie-react';

const IconsBanner = ({ _rawMain, _rawParagraph, socialMediaBoxes, image }) => {
  return (
    <section className="icons-banner pt-4 pb-5 h-100">
      <div className="icons-banner--background-secondary d-none d-md-block"></div>
      <div className="icons-banner--background"></div>
      <Container className="h-100">
        <Row className="icons-banner__main-row h-100">
          <Col className="icons-banner__text-container" xs={12} lg={4}>
            <img src={StarIcon} alt="" className="icons-banner__icon-star" />
            <BlockText data={_rawMain} className="icons-banner__title" />
            <Row
              className="icons-banner__image-container d-md-none d-lg-none d-xl-none"
              lg={4}
            >
              <ImageComponent data={image} className="icons-banner__image" />
              <Lottie
                className="icons-banner__lottie"
                animationData={SpecialAnimation}
              />
            </Row>
            <BlockText
              data={_rawParagraph}
              className="icons-banner__description"
            />
          </Col>
          <Col
            className="icons-banner__image-container d-none d-md-block"
            lg={4}
          >
            <ImageComponent data={image} className="icons-banner__image" />
            <Lottie
              className="icons-banner__lottie"
              animationData={SpecialAnimation}
            />
          </Col>
          <Col className="icons-banner__media-boxes-container" lg={4} xs={12}>
            <Row className=" d-flex flex-md-column mx-0">
              {socialMediaBoxes.map((box) => (
                <Col xs={4} className="px-1 mb-md-3">
                  <div className="icons-banner__media-boxes">
                    <div className="icons-banner__media-boxes__icon-container">
                      <ImageComponent
                        data={box.icon}
                        className="icons-banner__icon img-fluid"
                      />
                    </div>
                    <div className="icons-banner__media-boxes__title" xs={3}>
                      {box.title}
                    </div>
                    <div
                      className="icons-banner__media-boxes__description"
                      xs={3}
                    >
                      {box.description}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default IconsBanner;
