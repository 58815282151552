import React from 'react';

const CarouselNextArrow = (props) => {
  const { onClick } = props;
  const classNames = props.className;
  const dontShowArrow = 'slick-disabled';
  const showArrow = classNames.includes(dontShowArrow);
  return (
    <button className="next next--icon" onClick={onClick}>
      {!showArrow && <i className="icon-ic_right" />}
    </button>
  );
};

const CarouselPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <button className="prev prev--icon" onClick={onClick}>
      {props.currentSlide !== 0 && <i className="icon-ic_left" />}
    </button>
  );
};

export { CarouselNextArrow, CarouselPrevArrow };
