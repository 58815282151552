import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import { Col, Row } from 'reactstrap';
import ImageComponent from '../image';
import BlockText from '../block-text';

const VideoComponent = ({
  _key,
  url,
  _rawProfession,
  _rawTalentName,
  profilePicture,
  countryFlag,
  index,
  currentIndex,
}) => {
  const [preload, setPreload] = useState('none');
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);
  const containerRef = useRef();

  const mouseEnter = () => {
    setPlaying(true);
  };

  const mouseLeave = () => {
    setPlaying(false);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const toggleMuted = () => {
    setMuted(!muted);
  };

  useEffect(() => {
    setPreload('auto');
  }, []);

  useEffect(() => {
    if (currentIndex !== index) {
      setPlaying(false);
    }
  }, [index, currentIndex]);

  return (
    <div
      className="position-relative"
      onMouseOver={mouseEnter}
      onMouseOut={mouseLeave}
      role="button"
      ref={containerRef}
      tabIndex={0}
    >
      <button className="video-carousel__mute-icon" onClick={toggleMuted}>
        <i
          className={classNames(
            { 'icon-ic_mute': muted },
            { 'icon-ic_mute_active': !muted },
          )}
        />
      </button>
      <div onClick={togglePlay}>
        <ReactPlayer
          key={`${_key}-${preload}`}
          volume
          url={url}
          width="98%"
          height="98%"
          playsinline
          loop
          playing={playing}
          muted={muted}
          config={{
            file: {
              attributes: {
                preload,
              },
            },
          }}
          fallback={() => (
            <ImageComponent
              data={profilePicture}
              className="video-container-carousel__icon"
            />
          )}
        />
      </div>
      <div className="video-container-carousel__talent-info">
        <Row className="video-container-carousel__row-container">
          <Col xs={3} lg={3}>
            <ImageComponent
              data={profilePicture}
              className="video-container-carousel__icon"
            />
          </Col>
          <Col className="video-container-carousel" xs={6}>
            <ImageComponent
              data={countryFlag}
              className="video-container-carousel__flag"
            />
            <BlockText
              data={_rawProfession}
              className="video-container-carousel__talent-profesion"
            />
            <BlockText
              data={_rawTalentName}
              className="video-container-carousel__talent-name"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default VideoComponent;
