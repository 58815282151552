import React, { useState } from 'react';
import { Container, Col } from 'reactstrap';
import Slider from 'react-slick';
import VideoComponent from '../../components/ui/video';
import {
  CarouselNextArrow,
  CarouselPrevArrow,
} from '../../components/ui/arrows-carousel';

const VideoCarousel = ({ video }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
    afterChange: (currentSlide) => {
      setCurrentIndex(currentSlide);
    },

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: true,
          centerPadding: '40px',
          infinite: false,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '40px',
          centerMode: true,
          infinite: false,
        },
      },
    ],
  };
  return (
    <section className="video-carousel">
      <Container className="h-100 d-none d-md-block">
        <Col className="video-carousel__container" xs={12}>
          <Slider {...settings}>
            {video.map((single) => {
              return (
                <div key={single}>
                  <h3 className="video-carousel--player">
                    <div className="video-carousel__video-container">
                      <VideoComponent
                        url={single.link}
                        _rawProfession={single._rawProfession}
                        _rawTalentName={single._rawTalentName}
                        profilePicture={single.profilePicture}
                        countryFlag={single.countryFlag}
                      />
                    </div>
                  </h3>
                </div>
              );
            })}
          </Slider>
        </Col>
      </Container>
      <Slider className="d-md-none video-carousel__mobile" {...settings}>
        {video.map((single, index) => {
          return (
            <div key={single}>
              <h3 className="video-carousel--player">
                <div className="video-carousel__video-container">
                  <VideoComponent
                    url={single.link}
                    _rawProfession={single._rawProfession}
                    _rawTalentName={single._rawTalentName}
                    profilePicture={single.profilePicture}
                    countryFlag={single.countryFlag}
                    index={index}
                    currentIndex={currentIndex}
                  />
                </div>
              </h3>
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default VideoCarousel;
