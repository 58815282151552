import React, { useState } from 'react';
import { Col, Container, Row, Input, Button } from 'reactstrap';
import { toast, ToastContainer } from 'react-toastify';
import BlockText from '../../components/ui/block-text';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import StarIcon from '../../assets/images/star.svg';
import googlePlayBadge from '../../assets/images/googlePlayBadge.svg';
import appStoreBadge from '../../assets/images/app-store-badge.svg';
import ImageComponent from '../../components/ui/image';

const SubscribeToNewsletter = ({
  _rawTitleNewsletter,
  _rawTitleNewsletterIcon,
  _rawDescriptionNewsletterIcon,
  _rawTitleIput,
  _rawDescriptionInput,
  appStoreLink,
  googlePlayLink,
  logoNewsletter,
  imageNewsletter,
  linkLogo,
}) => {
  const [email, setEmail] = useState('');

  const config = {
    position: 'bottom-right',
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await addToMailchimp(email, {}).then((data) => {
      if (data.result === 'success') {
        toast.success('Gracias por suscribirte!', { ...config });
      } else {
        toast.error('Revisa el e-mail que ingresaste e inténtalo de nuevo.', {
          ...config,
        });
      }
    });
    setEmail('');
  };

  return (
    <section className="subscribe-to-newsletter pt-4 pb-5">
      <Container>
        <Row className="h-100 align-items-center">
          <Col md={6}>
            <img src={StarIcon} alt="" className="d-none d-md-block" />
            <BlockText
              data={_rawTitleNewsletter}
              className="subscribe-to-newsletter__title py-3"
            />
            <ImageComponent
              data={imageNewsletter}
              className="subscribe-to-newsletter__image-small d-md-none d-lg-none d-xl-none img-fluid"
            />
            <Row>
              <Col lg={2} xs={3}>
                <a
                  href={linkLogo}
                  target="_blank"
                  rel="noreferrer"
                  className="subscribe-to-newsletter__icon d-block"
                >
                  <ImageComponent data={logoNewsletter} className="w-100" />
                </a>
              </Col>
              <Col lg={10} xs={9}>
                <BlockText
                  className="subscribe-to-newsletter__icon-title"
                  data={_rawTitleNewsletterIcon}
                />
                <BlockText
                  className="subscribe-to-newsletter__icon-text"
                  data={_rawDescriptionNewsletterIcon}
                />
              </Col>
            </Row>
            <Row className="mt-5">
              <Col
                className="subscribe-to-newsletter__badges-container"
                xs={12}
              >
                <a href={googlePlayLink.link} target={googlePlayLink.target}>
                  <img
                    src={googlePlayBadge}
                    alt=""
                    className="subscribe-to-newsletter__badges-google  img-fluid"
                  />
                </a>
                <a href={appStoreLink.link} target={appStoreLink.target}>
                  <img
                    src={appStoreBadge}
                    alt=""
                    className="subscribe-to-newsletter__badges-apple  img-fluid"
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col className=" d-none d-md-block" md={6}>
            <ImageComponent
              data={imageNewsletter}
              className="subscribe-to-newsletter__image img-fluid"
            />
          </Col>
        </Row>
        <Row className="subscribe-to-newsletter__subscribe-container">
          <Col lg={5}>
            <BlockText
              className="subscribe-to-newsletter__input-title"
              data={_rawTitleIput}
            />
            <BlockText
              className="subscribe-to-newsletter__input-text"
              data={_rawDescriptionInput}
            />
          </Col>
          <Col lg={5}>
            <form onSubmit={handleSubmit}>
              <Row className="subscribe-to-newsletter__input-container">
                <Col className="subscribe-to-newsletter__input-section" md={10}>
                  <Input
                    id="email"
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="subscribe-to-newsletter__input"
                    required
                  />
                  <i className="icon-ic_mail subscribe-to-newsletter__mail-icon" />
                </Col>
                <Col md={2}>
                  <Button
                    type="submit"
                    className="subscribe-to-newsletter__button"
                    color="#A515DB"
                  >
                    ENVIAR
                  </Button>
                </Col>
                <ToastContainer
                  position="bottom-right"
                  autoClose={2500}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SubscribeToNewsletter;
