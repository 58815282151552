import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BlockText from '../../components/ui/block-text';
import ImageComponent from '../../components/ui/image';
import StarIcon from '../../assets/images/starIconDark.svg';
import BackgroundStar from '../../assets/images/im_forma@3X.png';
import googlePlayBadge from '../../assets/images/googlePlayBadge.svg';
import appStoreBadge from '../../assets/images/app-store-badge.svg';

const BannerHome = ({
  _rawMain,
  _rawParagraph,
  image,
  appStoreLink,
  googlePlayLink,
}) => {
  return (
    <section className="home-banner">
      <div className="home--background"></div>
      <Container className="home__container">
        <Row className="home__row h-100 flex-lg-row flex-column-reverse align-items-center">
          <Col className="home" xs={12} lg={6}>
            <img
              src={StarIcon}
              alt=""
              className="home__icon  animate__animated animate__bounceInDown img-fluid main__logo"
            />
            <BlockText data={_rawMain} />
            <BlockText className="home__description" data={_rawParagraph} />
            <Col>
              <Row>
                <Col className="home__badges-container" xs={12}>
                  <a href={googlePlayLink.link} target={googlePlayLink.target}>
                    <img
                      src={googlePlayBadge}
                      alt=""
                      className="home__badges-google img-fluid"
                    />
                  </a>
                  <a href={appStoreLink.link} target={appStoreLink.target}>
                    <img
                      src={appStoreBadge}
                      alt=""
                      className="home__badges-apple img-fluid"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col xs={12} lg={6} className="home__image-container">
            <ImageComponent
              data={image}
              deskProps={{
                width: 540,
                height: 560,
              }}
              mobileProps={{
                width: 366,
                height: 380,
              }}
              className="home__image img-fluid"
            />
            <img src={BackgroundStar} className="iconAnimate" alt="" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default BannerHome;
