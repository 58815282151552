const SERVICE_PRICES = {
  category: {
    musica: {
      Serenata: {
        old: {
          COP: {
            min: '$39.900',
            max: '$79.900',
          },
          USD: {
            min: '$12.99',
            max: '$25.99',
          },
        },
        bronze: {
          COP: {
            min: '$79.900',
            max: '$149.900',
          },
          USD: {
            min: '$19.99',
            max: '$39.99',
          },
        },
        silver: {
          COP: {
            min: '$149.900',
            max: '$199.900',
          },
          USD: {
            min: '$39.99',
            max: '49.99',
          },
        },
        gold: {
          COP: {
            min: '$199.900',
            max: '$299.900',
          },
          USD: {
            min: '$49.99',
            max: '$79.99',
          },
        },
        diamond: {
          COP: {
            min: '$299.900',
            max: '$499.900',
          },
          USD: {
            min: '$79.99',
            max: '$129.99',
          },
        },
        platino: {
          COP: {
            min: '$499.900',
            max: '$999.900',
          },
          USD: {
            min: '$129.99',
            max: '$249.99',
          },
        },
        palladium: {
          COP: {
            min: '$990.900',
            max: '$1.999.900',
          },
          USD: {
            min: '$249.99',
            max: '$499.99',
          },
        },
        top: {
          COP: {
            min: '$1.990.900',
            max: '$4.999.900',
          },
          USD: {
            min: '$499.99',
            max: '$999.99',
          },
        },
      },
      Saludo: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$49.900',
            max: '$99.900',
          },
          USD: {
            min: '$12.99',
            max: '$24.99',
          },
        },
        silver: {
          COP: {
            min: '$99.900',
            max: '$149.900',
          },
          USD: {
            min: '$24.99',
            max: '$39.99',
          },
        },
        gold: {
          COP: {
            min: '$149.990',
            max: '$199.900',
          },
          USD: {
            min: '$39.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$399.900',
          },
          USD: {
            min: '$49.99',
            max: '$99.99',
          },
        },
        platino: {
          COP: {
            min: '$399.900',
            max: '$799.900',
          },
          USD: {
            min: '$99.99',
            max: '$199.99',
          },
        },
        palladium: {
          COP: {
            min: '$790.900',
            max: '$1.499.900',
          },
          USD: {
            min: '$199.99',
            max: '$379.99',
          },
        },
        top: {
          COP: {
            min: '$1.499.900',
            max: '$3.999.900',
          },
          USD: {
            min: '$379.99',
            max: '$999.99',
          },
        },
      },
      Seguir: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$49.900',
            max: '$99.900',
          },
          USD: {
            min: '$12.99',
            max: '$24.99',
          },
        },
        silver: {
          COP: {
            min: '$99.900',
            max: '$149.900',
          },
          USD: {
            min: '$24.99',
            max: '$39.99',
          },
        },
        gold: {
          COP: {
            min: '$149.990',
            max: '$199.900',
          },
          USD: {
            min: '$39.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$399.900',
          },
          USD: {
            min: '$49.99',
            max: '$99.99',
          },
        },
        platino: {
          COP: {
            min: '$399.900',
            max: '$799.900',
          },
          USD: {
            min: '$99.99',
            max: '$199.99',
          },
        },
        palladium: {
          COP: {
            min: '$790.900',
            max: '$1.499.900',
          },
          USD: {
            min: '$199.99',
            max: '$379.99',
          },
        },
        top: {
          COP: {
            min: '$1.499.900',
            max: '$3.999.900',
          },
          USD: {
            min: '$379.99',
            max: '$999.99',
          },
        },
      },
      'Me gusta': {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$29.900',
          },
          USD: {
            min: '$3.99',
            max: '$7.99',
          },
        },
        silver: {
          COP: {
            min: '$29.900',
            max: '$49.900',
          },
          USD: {
            min: '$7.99',
            max: '$12.99',
          },
        },
        gold: {
          COP: {
            min: '$49.990',
            max: '$89.900',
          },
          USD: {
            min: '$12.99',
            max: '$21.99',
          },
        },
        diamond: {
          COP: {
            min: '$89.900',
            max: '$149.900',
          },
          USD: {
            min: '$21.99',
            max: '$39.99',
          },
        },
        platino: {
          COP: {
            min: '$149.900',
            max: '$299.900',
          },
          USD: {
            min: '$39.99',
            max: '$74.99',
          },
        },
        palladium: {
          COP: {
            min: '$299.900',
            max: '$599.900',
          },
          USD: {
            min: '$74.99',
            max: '$149.99',
          },
        },
        top: {
          COP: {
            min: '$599.900',
            max: '$999.900',
          },
          USD: {
            min: '$149.99',
            max: '$249.99',
          },
        },
      },
      Comentario: {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$29.900',
          },
          USD: {
            min: '$3.99',
            max: '$7.99',
          },
        },
        silver: {
          COP: {
            min: '$29.900',
            max: '$49.900',
          },
          USD: {
            min: '$7.99',
            max: '$12.99',
          },
        },
        gold: {
          COP: {
            min: '$49.990',
            max: '$89.900',
          },
          USD: {
            min: '$12.99',
            max: '$21.99',
          },
        },
        diamond: {
          COP: {
            min: '$89.900',
            max: '$149.900',
          },
          USD: {
            min: '$21.99',
            max: '$39.99',
          },
        },
        platino: {
          COP: {
            min: '$149.900',
            max: '$299.900',
          },
          USD: {
            min: '$39.99',
            max: '$74.99',
          },
        },
        palladium: {
          COP: {
            min: '$299.900',
            max: '$599.900',
          },
          USD: {
            min: '$74.99',
            max: '$149.99',
          },
        },
        top: {
          COP: {
            min: '$599.900',
            max: '$999.900',
          },
          USD: {
            min: '$149.99',
            max: '$249.99',
          },
        },
      },
    },
    creadorContenido: {
      Saludo: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$49.900',
            max: '$99.900',
          },
          USD: {
            min: '$12.99',
            max: '$24.99',
          },
        },
        silver: {
          COP: {
            min: '$99.900',
            max: '$149.900',
          },
          USD: {
            min: '$24.99',
            max: '$39.99',
          },
        },
        gold: {
          COP: {
            min: '$149.990',
            max: '$199.900',
          },
          USD: {
            min: '$39.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$399.900',
          },
          USD: {
            min: '$49.99',
            max: '$99.99',
          },
        },
        platino: {
          COP: {
            min: '$399.900',
            max: '$799.900',
          },
          USD: {
            min: '$99.99',
            max: '$199.99',
          },
        },
        palladium: {
          COP: {
            min: '$790.900',
            max: '$1.499.900',
          },
          USD: {
            min: '$199.99',
            max: '$379.99',
          },
        },
        top: {
          COP: {
            min: '$1.499.900',
            max: '$3.999.900',
          },
          USD: {
            min: '$379.99',
            max: '$999.99',
          },
        },
      },
      Seguir: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$49.900',
            max: '$99.900',
          },
          USD: {
            min: '$12.99',
            max: '$24.99',
          },
        },
        silver: {
          COP: {
            min: '$99.900',
            max: '$149.900',
          },
          USD: {
            min: '$24.99',
            max: '$39.99',
          },
        },
        gold: {
          COP: {
            min: '$149.990',
            max: '$199.900',
          },
          USD: {
            min: '$39.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$399.900',
          },
          USD: {
            min: '$49.99',
            max: '$99.99',
          },
        },
        platino: {
          COP: {
            min: '$399.900',
            max: '$799.900',
          },
          USD: {
            min: '$99.99',
            max: '$199.99',
          },
        },
        palladium: {
          COP: {
            min: '$790.900',
            max: '$1.499.900',
          },
          USD: {
            min: '$199.99',
            max: '$379.99',
          },
        },
        top: {
          COP: {
            min: '$1.499.900',
            max: '$3.999.900',
          },
          USD: {
            min: '$379.99',
            max: '$999.99',
          },
        },
      },
      'Me gusta': {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$29.900',
          },
          USD: {
            min: '$3.99',
            max: '$7.99',
          },
        },
        silver: {
          COP: {
            min: '$29.900',
            max: '$49.900',
          },
          USD: {
            min: '$7.99',
            max: '$12.99',
          },
        },
        gold: {
          COP: {
            min: '$49.990',
            max: '$89.900',
          },
          USD: {
            min: '$12.99',
            max: '$21.99',
          },
        },
        diamond: {
          COP: {
            min: '$89.900',
            max: '$149.900',
          },
          USD: {
            min: '$21.99',
            max: '$39.99',
          },
        },
        platino: {
          COP: {
            min: '$149.900',
            max: '$299.900',
          },
          USD: {
            min: '$39.99',
            max: '$74.99',
          },
        },
        palladium: {
          COP: {
            min: '$299.900',
            max: '$599.900',
          },
          USD: {
            min: '$74.99',
            max: '$149.99',
          },
        },
        top: {
          COP: {
            min: '$599.900',
            max: '$999.900',
          },
          USD: {
            min: '$149.99',
            max: '$249.99',
          },
        },
      },
      Comentario: {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$29.900',
          },
          USD: {
            min: '$3.99',
            max: '$7.99',
          },
        },
        silver: {
          COP: {
            min: '$29.900',
            max: '$49.900',
          },
          USD: {
            min: '$7.99',
            max: '$12.99',
          },
        },
        gold: {
          COP: {
            min: '$49.990',
            max: '$89.900',
          },
          USD: {
            min: '$12.99',
            max: '$21.99',
          },
        },
        diamond: {
          COP: {
            min: '$89.900',
            max: '$149.900',
          },
          USD: {
            min: '$21.99',
            max: '$39.99',
          },
        },
        platino: {
          COP: {
            min: '$149.900',
            max: '$299.900',
          },
          USD: {
            min: '$39.99',
            max: '$74.99',
          },
        },
        palladium: {
          COP: {
            min: '$299.900',
            max: '$599.900',
          },
          USD: {
            min: '$74.99',
            max: '$149.99',
          },
        },
        top: {
          COP: {
            min: '$599.900',
            max: '$999.900',
          },
          USD: {
            min: '$149.99',
            max: '$249.99',
          },
        },
      },
      Serenata: {
        old: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        bronze: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        silver: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        gold: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        diamond: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        platino: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        palladium: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        top: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
      },
    },
    deportista: {
      Saludo: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      Seguir: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      'Me gusta': {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Comentario: {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Serenata: {
        old: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        bronze: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        silver: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        gold: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        diamond: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        platino: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        palladium: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        top: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
      },
    },
    tvMas: {
      Saludo: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      Seguir: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      'Me gusta': {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Comentario: {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Serenata: {
        old: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        bronze: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        silver: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        gold: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        diamond: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        platino: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        palladium: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        top: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
      },
    },
    modelo: {
      Saludo: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      Seguir: {
        old: {
          COP: {
            min: '$19.900',
            max: '$49.900',
          },
          USD: {
            min: '$4.99',
            max: '$12.99',
          },
        },
        bronze: {
          COP: {
            min: '$39.900',
            max: '$89.900',
          },
          USD: {
            min: '$9.99',
            max: '$21.99',
          },
        },
        silver: {
          COP: {
            min: '$89.900',
            max: '$139.900',
          },
          USD: {
            min: '$21.99',
            max: '$34.99',
          },
        },
        gold: {
          COP: {
            min: '$139.990',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        diamond: {
          COP: {
            min: '$199.900',
            max: '$349.900',
          },
          USD: {
            min: '$49.99',
            max: '$89.99',
          },
        },
        platino: {
          COP: {
            min: '$349.900',
            max: '$599.900',
          },
          USD: {
            min: '$89.99',
            max: '$149.99',
          },
        },
        palladium: {
          COP: {
            min: '$599.900',
            max: '$1.299.900',
          },
          USD: {
            min: '$149.99',
            max: '$329.99',
          },
        },
        top: {
          COP: {
            min: '$1.299.900',
            max: '$2.999.900',
          },
          USD: {
            min: '$329.99',
            max: '$749.99',
          },
        },
      },
      'Me gusta': {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Comentario: {
        old: {
          COP: {
            min: '$8.900',
            max: '$12.900',
          },
          USD: {
            min: '$1.99',
            max: '$3.99',
          },
        },
        bronze: {
          COP: {
            min: '$12.900',
            max: '$19.900',
          },
          USD: {
            min: '$3.99',
            max: '$4.99',
          },
        },
        silver: {
          COP: {
            min: '$19.900',
            max: '$39.900',
          },
          USD: {
            min: '$4.99',
            max: '$9.99',
          },
        },
        gold: {
          COP: {
            min: '$39.990',
            max: '$79.900',
          },
          USD: {
            min: '$9.99',
            max: '$19.99',
          },
        },
        diamond: {
          COP: {
            min: '$79.900',
            max: '$139.900',
          },
          USD: {
            min: '$19.99',
            max: '$34.99',
          },
        },
        platino: {
          COP: {
            min: '$139.900',
            max: '$199.900',
          },
          USD: {
            min: '$34.99',
            max: '$49.99',
          },
        },
        palladium: {
          COP: {
            min: '$199.900',
            max: '$499.900',
          },
          USD: {
            min: '$49.99',
            max: '$129.99',
          },
        },
        top: {
          COP: {
            min: '$499.900',
            max: '$899.900',
          },
          USD: {
            min: '$129.99',
            max: '$229.99',
          },
        },
      },
      Serenata: {
        old: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        bronze: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        silver: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        gold: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        diamond: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        platino: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        palladium: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
        top: {
          COP: {
            min: ' N/A',
            max: ' N/A',
          },
          USD: {
            min: 'N/A',
            max: 'N/A',
          },
        },
      },
    },
  },
};

export default SERVICE_PRICES;
