import React from 'react';
import { Table } from 'reactstrap';
import prices from '../../utils/calculator-prices';

const priceTable = ({ job, level, services, currency }) => {
  return (
    <div className="calculator__table--background">
      <Table className="calculator__table" borderless striped>
        <thead className="calculator__table__title">
          <tr>
            <th>Servicio</th>
            <th>Tarifa mínima</th>
            <th>Tarifa máxima</th>
          </tr>
        </thead>
        <tbody className="calculator__table__body">
          {services &&
            services.map((service) => {
              return (
                <tr>
                  <th scope="row">{service}</th>
                  <td>{prices.category[job][service][level][currency].min}</td>
                  <td>{prices.category[job][service][level][currency].max}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default priceTable;
