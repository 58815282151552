import React from "react"
import BlockText from "../../components/ui/block-text"

const Faqs = ({ _rawQuestion, _rawTitleSection, bffIcon }) => {
  return (
    <>
      <div>
        <h1>Faqs</h1>
        <BlockText data={_rawTitleSection} />
      </div>
    </>
  )
}

export default Faqs
