import React from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'gatsby';

import BlockText from '../../components/ui/block-text';

const PrivacyPolicy = ({ _rawTitle, _rawStatements }) => {
  return (
    <section className="privacy-policy">
      <Container className="privacy-policy__container">
        <Link to="/">
          <Button className="privacy-policy__button--back px-0" color="#FAF1E0">
            <i className="icon-ic_arrow" /> Ir al inicio
          </Button>
        </Link>
        <BlockText data={_rawTitle} className="privacy-policy__title" />
        <BlockText
          data={_rawStatements}
          className="privacy-policy__description"
        />
      </Container>
    </section>
  );
};

export default PrivacyPolicy;
