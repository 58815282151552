import React from 'react';
import Arrow from '../../assets/images/arrow.svg';

const CarouselNextArrow = (props) => {
  const { onClick } = props;

  return (
    <button className="carousel-next" onClick={onClick}>
      {props.currentSlide !== 2 && (
        <>
          <span className="carousel-next--text">
            PASO {props.currentSlide === 0 ? '2' : '3'}
          </span>
          <img src={Arrow} alt="next-arrow" className="carousel-next--icon" />
        </>
      )}
    </button>
  );
};

const CarouselPrevArrow = (props) => {
  const { onClick } = props;

  return (
    <button className="carousel-prev" onClick={onClick}>
      {props.currentSlide !== 0 && (
        <>
          <span className="carousel-prev--text">
            PASO {props.currentSlide === 1 ? '1' : '2'}
          </span>
          <img src={Arrow} alt="prev-arrow" className="carousel-prev--icon" />
        </>
      )}
    </button>
  );
};

export { CarouselNextArrow, CarouselPrevArrow };
