import React, { useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import ImageComponent from '../../components/ui/image';

const ToTopButton = ({ title, buttonArrow }) => {
  const [show, setShow] = useState(false);

  const handleScroll = () => {
    const currentHeight = window.innerHeight / 2;
    if (window.scrollY > currentHeight) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {show && (
        <section className="to-top d-block d-sm-none">
          <Container>
            <Row
              className="to-top__icon-container"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            >
              <ImageComponent
                data={buttonArrow}
                className="to-top__icon-container__icon"
              />
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ToTopButton;
