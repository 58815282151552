import React, { useState } from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from 'reactstrap';
import ImageComponent from '../../components/ui/image';

const ServicesInfo = ({ index, icon, title, description }) => {
  const [open, setOpen] = useState();
  const toggle = (id) => {
    open === id ? setOpen() : setOpen(id);
  };
  return (
    <div className="calculator__content-container">
      <Accordion flush open={open} toggle={toggle}>
        <AccordionItem>
          <AccordionHeader targetId={index}>
            <ImageComponent className="calculator__box-icon" data={icon} />{' '}
            <span className="calculator__collapse-title">{title}</span>
          </AccordionHeader>
          <AccordionBody accordionId={index}>
            <p className="calculator__card-text">{description}</p>
          </AccordionBody>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ServicesInfo;
